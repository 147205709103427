import React, { FC } from "react"
// Using loadable to split JS bundles per component for quick loading of home page
import loadable from "@loadable/component"
import { Helmet } from "react-helmet"

import Layout from "../containers/layout"
import Banner from "../components/banner"
const About = loadable(() => import("../components/about"))
const Services = loadable(() => import("../components/services"))
const Hero = loadable(() => import("../components/hero"))
const Work = loadable(() => import("../components/work"))
// import Testimonials from "../components/testimonials"
const Lab = loadable(() => import("../components/lab"))

const IndexPage: FC = () => (
  // TODO: Used Styled (https://theme-ui.com/styled) as opposed to <Header>, <a> etc... everywhere and add styles.root as per the docs to make sure everything is consistent
  <Layout>
    <Helmet>
      <title>Portfolio</title>
    </Helmet>
    <Banner />
    <About />
    <Services />
    <Work />
    <Hero />
    {/* TODO: Complete testimonials */}
    {/* <Testimonials /> */}
    {/* TODO: Complete lab */}
    <Lab />
  </Layout>
)

export default IndexPage
