/* eslint-disable react-hooks/exhaustive-deps */
// FIXME: Disabled linting here due to the titles issue

import React, { useEffect, useState, FC } from "react"
import { useStaticQuery, graphql } from "gatsby"
import u from "umbrellajs"
import anime from "animejs/lib/anime.es.js"
import { Flex, Box, Heading } from "theme-ui"
import loadable from "@loadable/component"

import Logo from "../logo"
const Scroller = loadable(() => import("./scroller"))

export const bannerOverflow = 300

const Banner: FC = () => {
  const { background } = useStaticQuery(graphql`
    query {
      background: imageSharp(original: { src: { regex: "/banner-bg/" } }) {
        fixed(quality: 95, width: 1440, height: 1302, toFormat: WEBP) {
          src
        }
      }
    }
  `)

  const [titleIndx, setTitleIndx] = useState(0)
  const titles = [
    "Developer",
    "Craftsman",
    "Designer",
    "Evangilist",
    "Expert",
    "Specialist",
    "Addict",
    "Guru",
    "Engineer",
  ]

  useEffect(() => {
    (async () => {
      const title = u("[data-title]", React.createRef().current)
      const $title = title.first()
      const $rotator = title.find("span").first()
      const distance = 30 // px
      const delay = 1250 // milliseconds
      const duration = 500 // milliseconds

      // Bring in the title
      await anime({
        targets: $title,
        autoplay: true,
        duration: 750,
        opacity: 1,
      }).finished

      // Animate the title
      anime.timeline({
        targets: $rotator,
        autoplay: true,
        loop: true,
        duration: duration,
      }).add({
        opacity: 0,
        delay,
        translateY: -distance,
        easing: 'easeInExpo',
        changeComplete: () => {
          setTitleIndx((titleIndx) => {
            let newTitleIndx = 0

            if (titleIndx !== titles.length - 1) {
              newTitleIndx = titleIndx + 1
            }

            return newTitleIndx
          })
        }
      }).add({
        opacity: 1,
        translateY: [distance, 0],
        scale: [0.85, 1],
        easing: 'easeOutExpo',
        endDelay: delay,
      })
    })()

    return () => {
      for (let animation of anime.running) {
        animation.pause()
      }
    }
  }, [])

  return (
    <Flex
      color={"white-clean"}
      sx={{
        backgroundImage: `url(${background.fixed.src})`,
        backgroundAttachment: "fixed",
        backgroundSize: "cover",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        height: ["100vh", null, `calc(100vh + ${bannerOverflow}px)`], // TODO: Make the 300px dynamic based on the height of the avatar in about / 2
      }}
    >
      <Box
        mb={[0, null, bannerOverflow]}
        sx={{
          flex: "0 0 auto",
        }}
      >
        <Heading as="h1">
          <Logo size={250} />
        </Heading>
        <Heading
          data-title
          mt={3}
          sx={{
            fontWeight: "body",
            opacity: 0,
          }}
        >
          Web{" "}
          <Box
            as="span"
            sx={{
              display: "inline-block",
            }}
          >
            {titles[titleIndx]}
          </Box>
        </Heading>
      </Box>

      <Scroller />
    </Flex>
  )
}

export default Banner
